<template>
  <div class="alarm">
    <h1>报警记录</h1>
    <main class="hmain">
      <div class="history_input">
        <label>起始日期</label>
        <input type="date" v-model="times[0]" placeholder="起始日期"/>
      </div>
      <div class="history_input">
        <label>结束日期</label>
        <input type="date" v-model="times[1]" placeholder="结束日期"/>
      </div>
      <el-button type="primary" @click="bt_history">查询</el-button>
    </main>
    <el-table
      :data="$store.state.alarms"
      style="width: 100%"
      :row-class-name="tableRowClassName"
      :cell-class-name="tableCellClassName">
      <el-table-column prop="id" label=""></el-table-column>
      <el-table-column prop="[1]" label="日期" width="100"></el-table-column>
      <el-table-column prop="[2]" label="时间"></el-table-column>
      <el-table-column prop="[3]" label="主机"></el-table-column>
      <el-table-column prop="[4]" label="从机"></el-table-column>
      <el-table-column prop="[5]" label="报警"></el-table-column>
      <el-table-column prop="[6]" label="振动"></el-table-column>
      <el-table-column prop="[7]" label="温度"></el-table-column>
      <el-table-column prop="[8]" label="湿度"></el-table-column>
      <el-table-column prop="[9]" label="烟雾"></el-table-column>
      <el-table-column prop="[10]" label="电池电量"></el-table-column>
      <el-table-column prop="[11]" label="纬度"></el-table-column>
      <el-table-column prop="[12]" label="经度"></el-table-column>
    </el-table>
  </div>
</template>

<style>
  .el-table .warning-cell {
    font-size: large;
    color: red;
  }
</style>


<script>
import axios from 'axios'

export default {
  components: {
  },
  data() {
    return {
      times: ['2020-12-31','2020-12-31'],
      update: 0,
    };
  },
  computed: {
  },
  methods: {
    tableRowClassName({row, rowIndex}) {
      // console.log('tableRowClassName', row, rowIndex, row[8], typeof(row[8]));
      row.id=rowIndex+1
      if (row);
      if (rowIndex);
      if (row[8] != '0000') {
        // return 'warning-row';//'success-row'
      }
      return '';
    },
    tableCellClassName({row, column, rowIndex, columnIndex}){
      if (columnIndex==6 || columnIndex==7 || columnIndex==8 || columnIndex==8){
        if (row[5][columnIndex-6]=='1'){
          console.log("tableCellClassName", row, column, rowIndex, columnIndex);
          return 'warning-cell'
        }
      }
      return ''
    },
    bt_history(){
      var url='alarm.php'
      var ps={
        t1:this.times[0], 
        t2:this.times[1], 
      }
      var p=this
      axios.get(url, {params:ps}).then(function(rsp){
        console.log('rsp', typeof(rsp), rsp, rsp.data);
        if (Array.isArray(rsp.data)){
          p.$store.state.alarms=rsp.data
        }
        p.update++;
      }).catch(function(err){
        console.log('err', err);
      })
    }
  },
  created () {
  },
  mounted () {
    this.$store.state.alarm=false
    var now=new Date()
    this.$set(this.times, 0, new Date(now.getFullYear(), now.getMonth(), now.getDate()).format('yyyy-MM-dd'))
    this.$set(this.times, 1, new Date(now.getFullYear(), now.getMonth(), now.getDate()).format('yyyy-MM-dd'))
    this.bt_history()
  }
};
</script>
